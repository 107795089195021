<template>
  <v-layout>
    <OrdersAppBar
      :datasetIsSelected="selected"
      :searchTableIsActive="filterActive"
      @initHeaders="initialize"
      @filterChanged="setFilter"
      @resetFilters="resetFilter"
      @deliverSelectedDatasets="openDeliverDatasets"
      @delaySelectedDataset="openDelayDataset"
      @createPDF="downloadPDF"
    />

    <v-data-table
      v-model="selected"
      calculate-widths
      class="pl-1"
      height="calc(100vh - 124px - 69px)"
      fixed-header
      hide-default-footer
      :items="filteredByManufacturer"
      item-key="Bestellung_ID"
      :item-class="rowColor"
      :items-per-page="100"
      :headers="datasetHeaders"
      :page.sync="page"
      :search="filter.search"
      @page-count="pageCount = $event"
      @dblclick:row="showDataset"
      :single-select="false"
      :show-select="checkCurrentUser"
      mobile-breakpoint="300"
      style="width: 100%;"
    >
      <template #[`header.Artikelnummer`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchArticlenumber" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Artikelnummer
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchArticlenumber" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Artikelnummer
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchArticlenumber"
              dense
              outlined
              clearable
              hide-details
              autofocus
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`item.Artikelnummer`]="{ item }">
        <v-row dense align="center" justify="center" class="text-center">
          <v-icon v-if="item.verspätet" class="mr-1" color="error">mdi-clock-alert-outline</v-icon
          ><span class="font-weight-bold">{{ item.Artikelnummer }}</span>
        </v-row>
      </template>

      <template #[`item.Bestellmenge`]="{ item }">
        <span>{{ item.Bestellmenge + " " + item.Einheit }}</span>
      </template>

      <template #[`item.Preis`]="{ item }">
        <span>{{ item.Preis + " €" }}</span>
      </template>

      <template #[`header.Größe`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchSize" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Größe
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchSize" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Größe
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchSize" dense clearable autofocus outlined hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Farben`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchColor" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Farbe(n)
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchColor" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Farbe(n)
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchColor" dense clearable autofocus outlined hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Stoffe`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchFabric" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Stoff(e)
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchFabric" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Stoff(e)
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchFabric" dense clearable autofocus outlined hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Schnitt`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchCut" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Schnitt
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchCut" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Schnitt
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchCut" dense clearable outlined autofocus hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Besonderheiten`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchFeature" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Besonderheiten
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchFeature" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Besonderheiten
                <v-icon color="red lighten-2" right>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field v-model="tableSearch.searchFeature" dense clearable outlined autofocus hide-details placeholder="Filtern"></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Hersteller`]="{  }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-small text v-bind="attrs" v-on="on" class="px-0">
              <span v-if="!tableSearch.searchManufacturer" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Hersteller
                <v-icon small>mdi-magnify</v-icon>
              </span>
              <span v-if="tableSearch.searchManufacturer" class="mx-auto text--primary text-uppercase caption font-weight-bold"
                >Hersteller
                <v-icon color="red lighten-2" small>mdi-alert</v-icon>
              </span>
            </v-btn>
          </template>
          <v-card outlined>
            <v-text-field
              v-model="tableSearch.searchManufacturer"
              dense
              clearable
              outlined
              hide-details
              autofocus
              placeholder="Filtern"
            ></v-text-field>
          </v-card>
        </v-menu>
      </template>

      <template #[`header.Lieferung`]="{  }">
        <span class="mx-auto text--primary text-uppercase caption font-weight-bold">vsl. Lieferung </span>
      </template>

      <template #[`item.Lieferung`]="{ item }">
        <span v-if="!item.Lieferdatum" class="font-weight-bold">{{
          item.Lieferzeitraum || new Date(item.Lieferdatum).toLocaleDateString("de-DE")
        }}</span>
        <span v-if="!item.Lieferzeitraum" class="font-weight-bold">{{ item.Lieferdatum }}</span>
      </template>

      <template #[`item.Bestellerfassung`]="{ item }">
        <span>{{ item.Bestellerfassung }}</span>
        <span>{{
          " (" +
            new Date(item.bestellt_am).toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }) +
            ")"
        }}</span>
      </template>

      <template v-slot:no-data>
        <v-row dense align="center" justify="center" style="height: calc(100vh - 242px);">
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="8">
            <span>keine Daten gefunden - bitte Filter anpassen oder neue Daten erstellen</span>
          </v-col>
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-results>
        <v-row dense align="center" justify="center" style="height: calc(100vh - 242px);">
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
          <v-col cols="8">
            <span>keine Daten gefunden - bitte Filter anpassen oder neue Daten erstellen</span>
          </v-col>
          <v-col cols="auto">
            <v-icon x-large>mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-footer app inset height="69" elevation="1">
      <v-col cols="12" class="pa-0">
        <v-row dense align="center" justify="start" class="px-1">
          <v-spacer></v-spacer>
          <v-col cols="8">
            <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-col>
    </v-footer>

    <DeliveredDatasets ref="DeliverDatasets" @confirmDeliveredDatasets="deliverDatasets" />

    <DelayDataset ref="DelayDataset" @confirmDelayedDataset="delayDataset" />
  </v-layout>
</template>

<script>
import jsPDF from "jspdf";
import OrdersAppBar from "../../components/appbars/OrdersAppBar.vue";
import DeliveredDatasets from "../../components/dialogs/DeliverDatasets.vue";
import DelayDataset from "../../components/dialogs/DelayDataset.vue";

export default {
  components: {
    OrdersAppBar,
    DeliveredDatasets,
    DelayDataset,
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.initialize();
  },

  data: () => ({
    filter: {
      search: "",
      placeholder: false,
    },
    tableSearch: {
      searchArticlenumber: null,
      searchSize: null,
      searchColor: null,
      searchFabric: null,
      searchCut: null,
      searchFeature: null,
      searchManufacturer: null,
    },
    datasets: [],
    datasetHeaders: [],
    ausgeblendeteSpalten: [],
    hiddenColumns: [],
    page: 1,
    pageCount: 0,
    selected: [],
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },

    filteredByPlaceholder() {
      if (this.datasets && !this.filter.placeholder) {
        return this.datasets.filter((item) => item.wurde_zugestellt === 0);
      } else if (this.datasets && this.filter.placeholder) {
        return this.datasets;
      }
    },

    filteredByArticlenumber() {
      if (this.tableSearch.searchArticlenumber) {
        return this.filteredByPlaceholder.filter(
          (item) => item.Artikelnummer != null && item.Artikelnummer.includes(this.tableSearch.searchArticlenumber)
        );
      } else return this.filteredByPlaceholder;
    },

    filteredBySize() {
      if (this.tableSearch.searchSize) {
        return this.filteredByArticlenumber.filter((item) => item.Größe != null && item.Größe.includes(this.tableSearch.searchSize));
      } else return this.filteredByArticlenumber;
    },

    filteredByColor() {
      if (this.tableSearch.searchColor) {
        return this.filteredBySize.filter((item) => item.Farben != null && item.Farben.includes(this.tableSearch.searchColor));
      } else return this.filteredBySize;
    },

    filteredByFarbic() {
      if (this.tableSearch.searchFabric) {
        return this.filteredByColor.filter((item) => item.Stoffe != null && item.Stoffe.includes(this.tableSearch.searchFabric));
      } else return this.filteredByColor;
    },

    filteredByCut() {
      if (this.tableSearch.searchCut) {
        return this.filteredByFarbic.filter((item) => item.Schnitt != null && item.Schnitt.includes(this.tableSearch.searchCut));
      } else return this.filteredByFarbic;
    },

    filteredByFeature() {
      if (this.tableSearch.searchFeature) {
        return this.filteredByCut.filter((item) => item.Besonderheiten != null && item.Besonderheiten.includes(this.tableSearch.searchFeature));
      } else return this.filteredByCut;
    },

    filteredByManufacturer() {
      if (this.tableSearch.searchManufacturer) {
        return this.filteredByFeature.filter((item) => item.Hersteller != null && item.Hersteller.includes(this.tableSearch.searchManufacturer));
      } else return this.filteredByFeature;
    },

    filterActive() {
      if (this.tableSearch.searchArticlenumber || this.tableSearch.searchManufacturer) {
        return true;
      } else return false;
    },
  },

  methods: {
    setFilter(newfilter) {
      this.filter = Object.assign({}, newfilter);
    },

    resetFilter() {
      this.tableSearch.searchArticlenumber = null;
      this.tableSearch.searchManufacturer = null;
    },

    resetSelected() {
      this.selected = [];
    },

    showDataset(event, { item }) {
      this.$router.push({
        path: `/wws/${this.$route.meta.request}/dataset/show/${item.Bestellung_ID}`,
      });
    },

    async initialize() {
      const response = await fetch(`/api/${this.$route.meta.request}/tableheaders`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.hiddenColumns = this.ausgeblendeteSpalten.map((item) => item.Spalte);

      this.resetFilter();

      this.initializeContent();
    },

    async initializeContent() {
      const response = await fetch(`/api/${this.$route.meta.request}/datasets`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      this.datasetHeaders = Object.keys(this.datasets[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: true,
            align: "center",
            divider: true,
            class: "text--primary text-uppercase",
          };
        });

      const markDatasetAsDelayed = this.datasets;
      const today = new Date().toISOString().substring(0, 10);

      markDatasetAsDelayed.forEach(async function(dataset) {
        if (dataset.wurde_zugestellt === 0 && dataset.Lieferung < today) {
          Object.assign(dataset, { verspätet: true });
        } else Object.assign(dataset, { verspätet: false });
      });
    },

    rowColor(item) {
      if (item.Bestellstatus_ID === 5 && !item.verspätet && item.wurde_zugestellt === 0) {
        return "vor-order";
      } else if (item.wurde_zugestellt === 1) {
        return "zugestellt";
      } else return "";
    },

    openDelayDataset() {
      this.$refs.DelayDataset.open(this.selected);
    },

    openDeliverDatasets() {
      this.$refs.DeliverDatasets.open(this.selected);
    },

    //TERMIN LÖSCHEN WENN LÖSCHUNG BESTÄTIGT
    async delayDataset(item, bearbeiter) {
      const datasetToUpdate = {};

      if (Object.keys(datasetToUpdate).length === 0) {
        Object.assign(datasetToUpdate, {
          Bestellung_ID: item.Bestellung_ID,
          Lieferzeitraum: item.Lieferzeitraum_neu,
          Lieferdatum: item.Lieferdatum_neu,
          letzter_Zustelltag: item.letzter_Zustelltag_neu,
        });
      }

      await fetch(`/api/${this.$route.meta.request}/order/delay`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(datasetToUpdate),
      });

      const orderEventToAdd = {};

      if (Object.keys(orderEventToAdd).length === 0) {
        Object.assign(orderEventToAdd, {
          Vorgang_ID: 15,
          Bestellung_ID: item.Bestellung_ID,
          Bearbeiter_ID: bearbeiter,
        });
      }

      await fetch(`/api/orders/proposal/order/event/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(orderEventToAdd),
      });

      this.$root.actionSnackbar.show({
        dataset: "Lieferung",
        action: "verschoben",
      });

      this.initialize();
      this.selected = [];

      setTimeout(() => {
        this.$root.mainmenu.reinitialize();
      }, 500);
    },

    deliverDatasets(items, bearbeiter) {
      const multipleDeliveries = items;
      const amountDeliveredDatasets = multipleDeliveries.length;
      const request = this.$route.meta.request;

      multipleDeliveries.forEach(async function(delivery) {
        const articleToDeliver = {};

        const today = new Date().toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit" });

        if (Object.keys(articleToDeliver).length === 0) {
          Object.assign(articleToDeliver, {
            Bestellung_ID: delivery.Bestellung_ID,
            Artikel_ID: delivery.Artikel_ID,
            wurde_zugestellt: 1,
            Bestellstatus_ID: 2,
            Status_ID: 2,
            zugestellt_am: today,
            Vorgang_ID: 7,
            zugestellt_durch: bearbeiter,
            Bearbeiter_ID: bearbeiter,
            Termin_ID: null,
            Kunden_ID: null,
            EK_netto: null,
            VK_brutto: null,
            Leihpreis: null,
          });
        }

        await fetch(`/api/${request}/order/delivered`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(articleToDeliver),
        });

        await fetch(`/api/${request}/order/delivered/updatearticlestatus`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(articleToDeliver),
        });

        await fetch(`/api/database/articles/dataset/event/insert`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(articleToDeliver),
        });

        const orderEventToAdd = {};

        if (Object.keys(orderEventToAdd).length === 0) {
          Object.assign(orderEventToAdd, {
            Vorgang_ID: 14,
            Bestellung_ID: delivery.Bestellung_ID,
            Bearbeiter_ID: bearbeiter,
          });
        }

        await fetch(`/api/orders/proposal/order/event/insert`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderEventToAdd),
        });
      });

      this.initialize();
      this.selected = [];

      this.$root.actionSnackbar.show({
        amount: String(amountDeliveredDatasets),
        dataset: "Artikel",
        action: "zugestellt",
      });

      setTimeout(() => {
        this.$root.mainmenu.reinitialize();
      }, 500);
    },

    downloadPDF() {
      var initialdata = this.filteredByProductline;

      initialdata.forEach(function(o) {
        Object.keys(o).forEach(function(key) {
          if (o[key] === null || o[key] === "") {
            o[key] = " ";
          }
        });
      });

      var data = initialdata;

      var initialheaders = this.datasetHeaders.map((item) => item.text);
      var headers = initialheaders;

      var dateandtime = new Date();
      var currentdate = dateandtime.toLocaleDateString("de-DE");

      if (headers.length > 4) {
        var doc = new jsPDF({
          orientation: "landscape",
          floatPrecision: "smart",
        });
        doc.table(1, 1, data, headers, {
          autoSize: true,
          fontSize: 10,
          padding: 2,
        });
        doc.save("Herstellerliste - Stand " + currentdate + ".pdf");
      } else if (headers.length <= 4) {
        var doc = new jsPDF({
          orientation: "portrait",
          floatPrecision: "smart",
        });
        doc.table(1, 1, data, headers, {
          autoSize: true,
          fontSize: 10,
          padding: 2,
        });
        doc.save("Herstellerliste - Stand " + currentdate + ".pdf");
      }

      this.initialize();
    },
  },
};
</script>

<style>
.vor-order {
  background: #ffb74d !important;
}

.zugestellt {
  background: #81c784 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  cursor: pointer;
  background: #ddddddc5 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.vor-order:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #ffb74dc5 !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.zugestellt:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #81c784c5 !important;
}
</style>
