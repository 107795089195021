<template>
  <v-dialog
    v-model="DelayDataset"
    @click:outside="clearBearbeiter"
    scrollable
    width="475px"
    overlay-opacity="0.75"
  >
    <v-card tile class="pa-3">
      <v-card-title
        ><v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title v-if="$route.meta.request === 'orders/ordered'"
                >Liefertermin verschieben</v-toolbar-title
              >
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>

      <v-card-text class="text--primary py-5 px-3 subtitle-2">
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-col cols="12" v-if="$route.meta.request === 'orders/ordered'"
              >Voraussichtliche Zustellung von {{dataset.Artikelnummer}} bis zum
              {{
                new Date(dataset.Lieferung).toLocaleDateString("de-DE", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              }}
              verzögert sich?
            </v-col>
            <v-col cols="12" v-if="$route.meta.request === 'orders/ordered'"
              >Bitte neuen Lieferzeitraum oder neues Lieferdatum festlegen:
            </v-col>
          </v-row>
          <v-row dense align="center">
            <v-col cols="12" xl="7" lg="7" md="7">
              <span class="font-weight-bold">neuer Lieferzeitraum</span>
              <v-menu
                v-model="dataset.datepicker1"
                :close-on-content-click="false"
                :nudge-right="30"
                transition="scale-transition"
                offset-y
                min-width="290"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataset.Lieferzeitraum_neu"
                    readonly
                    outlined
                    dense
                    hide-details
                    clearable
                    placeholder="Zeitraum"
                    @click:clear="$nextTick(() => resetDate1(dataset))"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dataset.date1"
                  locale="de"
                  full-width
                  no-title
                  range
                  @change="dateRange($event, dataset, dataset.date1)"
                  type="month"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col  cols="12" xl="5" lg="5" md="5">
              <span class="font-weight-bold">neues Lieferdatum</span>
              <v-menu
                v-model="dataset.datepicker2"
                :close-on-content-click="false"
                :nudge-right="30"
                transition="scale-transition"
                offset-y
                min-width="290"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dataset.Lieferdatum_neu"
                    readonly
                    outlined
                    dense
                    hide-details
                    clearable
                    placeholder="Datum"
                    @click:clear="$nextTick(() => resetDate2(dataset))"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dataset.date2"
                  @input="dataset.datepicker2 = false"
                  locale="de"
                  first-day-of-week="1"
                  full-width
                  no-title
                  @change="dateExact($event, dataset, dataset.date2)"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row dense align="center">
        <v-col cols="12">
          <span class="font-weight-bold">Bearbeiter(*)</span>
              <v-select
                hide-details
                dense
                outlined
                required
                placeholder="Bearbeiter(*)"
                v-model="selectedBearbeiter"
                :items="Bearbeiter"
                item-text="Vorname"
                item-value="Bearbeiter_ID"
              >
              </v-select>
        </v-col>
      </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                outlined
                color="red lighten-1"
                @click="abortDelayedDataset()"
              >
                <span>Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="green lighten-1"
                @click="confirmDelayedDataset()"
                :disabled="
                  selectedBearbeiter === null || (dataset.Lieferzeitraum_neu === null && dataset.Lieferdatum_neu === null)
                "
              >
                <v-icon left class="grey--text text--lighten-4">
                  mdi-check
                </v-icon>
                <span class="grey--text text--lighten-4">
                  Liefertermin verschieben
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    DelayDataset: false,
    dataset: [],
    datepicker1: false,
    datepicker2: false,
    Bearbeiter: [],
    selectedBearbeiter: null,
  }),

  methods: {
    open(item) {
      this.dataset = Object.assign({}, item[0]);
      this.DelayDataset = true;
      this.initialize();
    },

    async initialize() {
      const response = await fetch(`/api/database/articles/dataset/insert`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }
    },

    confirmDelayedDataset() {
      this.DelayDataset = false;
      this.$emit("confirmDelayedDataset", this.dataset, this.selectedBearbeiter);
    },

    clearBearbeiter() {
      this.selectedBearbeiter = {};
    },

    abortDelayedDataset() {
      this.DelayDataset = false;
    },

    dateRange(e, item, datum) {
      if (item.Lieferdatum_neu) {
        item.Lieferdatum_neu = null;
        item.date2 = null;
        item.letzter_Zustelltag_neu = null;
      }
      if (datum[0] > datum[1]) {
        const [firstyear, firstmonth] = datum[1].split("-");
        const firstdate = `${firstmonth}/${firstyear}`;
        const [secondyear, secondmonth] = datum[0].split("-");
        const seconddate = `${secondmonth}/${secondyear}`;
        const lieferzeitraum = `${firstdate} - ${seconddate}`;
        item.Lieferzeitraum_neu = lieferzeitraum;
        item.Lieferdatum_neu = null;

        const dayafter = new Date(secondyear, secondmonth, 1);
        const lastday = new Date(dayafter - 1).toISOString().substring(0, 10);

        Object.assign(item, { letzter_Zustelltag_neu: lastday });
      } else {
        const [firstyear, firstmonth] = datum[0].split("-");
        const firstdate = `${firstmonth}/${firstyear}`;
        const [secondyear, secondmonth] = datum[1].split("-");
        const seconddate = `${secondmonth}/${secondyear}`;
        const lieferzeitraum = `${firstdate} - ${seconddate}`;
        item.Lieferzeitraum_neu = lieferzeitraum;
        item.Lieferdatum_neu = null;

        const dayafter = new Date(secondyear, secondmonth, 1);
        const lastday = new Date(dayafter - 1).toISOString().substring(0, 10);

        Object.assign(item, { letzter_Zustelltag_neu: lastday });
      }
    },

    dateExact(e, item, datum) {
      if (item.Lieferzeitraum_neu) {
        item.Lieferzeitraum_neu = null;
        item.date1 = [];
        item.letzter_Zustelltag_neu = null;
      }
      if (datum) {
        const [year, month, day] = datum.split("-");
        const lieferdatum = `${day}.${month}.${year}`;
        item.Lieferdatum_neu = lieferdatum;
        item.Lieferzeitraum_neu = null;

        Object.assign(item, { letzter_Zustelltag_neu: datum });
      }
    },

    resetDate1(item) {
      item.Lieferzeitraum_neu = null;
      item.date1 = [];
      item.letzter_Zustelltag_neu = null;
    },

    resetDate2(item) {
      item.Lieferdatum_neu = null;
      item.date2 = null;
      item.letzter_Zustelltag_neu = null;
    },
  },
};
</script>
