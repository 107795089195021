<template>
  <v-app-bar app height="124" class="align-start elevation-1">
    <v-col cols="12" class="py-0">
      <v-row dense class="align-center">
        <v-col cols="auto" class="pa-0 flex-grow-1">
          <v-row dense class="align-center flex-nowrap" style="height: 60px;">
            <v-col cols="auto" class="d-flex d-lg-none pa-0">
              <!--Platzhalter für mobile Menu-->
              <div style="width: 50px"></div>
            </v-col>
            <v-col cols="auto">
              <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
            </v-col>
            <v-col cols="auto" v-if="filterIsActive" @click="resetFilters">
              <v-btn outlined color="red lighten-2"
                ><v-icon>mdi-filter-off</v-icon
                ><span class="ml-1 d-none d-lg-flex">Filter aktiv!</span></v-btn
              >
            </v-col>

            <v-spacer></v-spacer>
            <v-col cols="auto" v-if="checkCurrentUser">
              <v-btn
                color="green lighten-2 grey--text text--lighten-4"
                :disabled="datasetIsSelected.length === 0 || datasetIsSelected.length > 1 || datasetIsSelected[0].wurde_zugestellt === 1"
                @click="deliverDatasets()"
              >
                <v-icon>mdi-truck-delivery-outline</v-icon>
                <span class="ml-1 d-none d-lg-flex">Zugestellt</span>
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="checkCurrentUser">
              <v-btn
                color="blue lighten-2 grey--text text--lighten-4"
                :disabled="
                  datasetIsSelected.length === 0 || datasetIsSelected.length > 1
                "
                @click="delayDataset()"
              >
                <v-icon>mdi-calendar-clock-outline</v-icon>
                <span class="ml-1 d-none d-lg-flex">Lieferung verschieben</span>
              </v-btn>
            </v-col>
            <v-col cols="auto" v-if="checkCurrentUser">
              <v-btn
                color="orange lighten-2 grey--text text--lighten-4"
                :to="linkCreateDataset"
              >
                <v-icon>mdi-plus</v-icon>
                <span class="ml-1 d-none d-lg-flex">Vor-Order</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense class="align-center" style="height: 64px;">
            <v-col cols="4" xl="2" lg="3" md="4" sm="4">
              <v-text-field
                dense
                hide-details
                v-model="filter.search"
                prepend-icon="mdi-magnify"
                clearable
                outlined
                placeholder="Suche"
                @input="filterChange()"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-menu
                offset-y
                :nudge-left="266"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined v-bind="attrs" v-on="on">
                    <v-icon>mdi-cog</v-icon>
                  </v-btn>
                </template>
                <v-card width="330">
                  <v-card-title
                    ><span>Spalten ein/ausblenden:</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="text--primary">
                    <v-row dense align="center" justify="center">
                      <v-col
                        cols="6"
                        v-for="item in headersToToggle"
                        :key="item.Spalte"
                      >
                        <v-checkbox
                          hide-details
                          dense
                          v-model="item.nicht_versteckt"
                          @change="reactToChange($event, item.Spalte)"
                        >
                          <template v-slot:label>
                            <span class="text--primary">
                              {{ item.Spalte }}
                            </span>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="auto">
              <v-menu
                offset-y
                :nudge-left="266"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-card width="330">
                  <v-card-title><span>Weitere Optionen:</span> </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="text--primary">
                    <v-checkbox
                      dense
                      hide-details
                      v-model="filter.placeholder"
                      @change="filterChange()"
                    >
                      <template v-slot:label>
                        <span
                          v-if="$route.meta.request === 'orders/ordered'"
                          class="text--primary"
                          >zugestellte anzeigen</span
                        >
                      </template>
                    </v-checkbox>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-app-bar>
</template>

<script>
export default {
  created() {
    this.initialize();
  },

  props: {
    datasetIsSelected: { type: Array, default: [] },
    searchTableIsActive: { type: Boolean, default: false },
  },

  data: () => ({
    filter: {
      search: "",
      placeholder: null,
    },
    emptyFilter: {
      search: "",
      placeholder: null,
    },
    headersToToggle: [],
  }),

  computed: {
    checkCurrentUser() {
      const user = localStorage.getItem("user");

      if (user === "Aline" || user === "Admin") {
        return true;
      } else return false;
    },

    filterIsActive() {
      if (this.filter.search || this.searchTableIsActive) {
        return true;
      } else return false;
    },

    linkCreateDataset() {
      if (this.$route.meta.request) {
        return `/wws/${this.$route.meta.request}/dataset/create`;
      }
    },

    linkCopyDataset() {
      if (this.datasetIsSelected.length > 0) {
        return `/wws/${this.$route.meta.request}/dataset/copy/${this.datasetIsSelected[0].Artikel_ID}`;
      }
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(
        `/api/${this.$route.meta.request}/availabletableheaders`
      );
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }
      this.initializefilter();
    },

    async reactToChange(value, item) {
      if (value === true) {
        await fetch(
          `/api/${this.$route.meta.request}/tableheader/delete/${item}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        this.$emit("initHeaders", true);
      } else
        await fetch(
          `/api/${this.$route.meta.request}/tableheader/insert/${item}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      this.$emit("initHeaders", true);
    },

    initializefilter() {
      if (this.$route.meta.request === "orders/ordered") {
        const search = localStorage.getItem("ordersFilterSearch");

        if (search === "null" || search === null) {
          this.filter.search = "";
        } else this.filter.search = localStorage.getItem("ordersFilterSearch");

        const placeholder = localStorage.getItem("ordersFilterPlaceholder");

        if (placeholder === "false") {
          this.filter.placeholder = false;
        } else if (placeholder === "true") {
          this.filter.placeholder = true;
        } else this.filter.placeholder = false;
      }

      this.filterChange();
    },

    filterChange() {
      const newfilter = {
        search: this.filter.search,
        placeholder: this.filter.placeholder,
      };
      this.$emit("filterChanged", newfilter);

      if (this.$route.meta.request === "orders/ordered") {
        localStorage.setItem("ordersFilterSearch", this.filter.search);
        localStorage.setItem(
          "ordersFilterPlaceholder",
          this.filter.placeholder
        );
      }

      this.$emit("filterResetted", true);
    },

    resetFilters() {
      this.filter.search = null;
      this.$emit("resetFilters", true);
      this.filterChange();
    },

    delayDataset() {
      this.$emit("delaySelectedDataset", true);
    },

    deliverDatasets() {
      this.$emit("deliverSelectedDatasets", true);
    },

    generatePDF() {
      this.$emit("createPDF", true);
    },
  },
};
</script>
