<template>
  <v-dialog
    v-model="DeliverDatasets"
    @click:outside="clearBearbeiter"
    scrollable
    width="475px"
    overlay-opacity="0.75"
  >
    <v-card tile class="pa-3">
      <v-card-title
        ><v-col cols="12" class="pa-0">
          <v-row dense class="align-center">
            <v-col cols="auto">
              <v-toolbar-title v-if="$route.meta.request === 'orders/ordered'"
                >Zustellung erfassen</v-toolbar-title
              >
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>

      <v-card-text class="text--primary py-5 px-2 subtitle-2">
      <v-row dense align="center"> 
      <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-col cols="12" v-if="$route.meta.request === 'orders/ordered'"
              >Wurden folgende Artikel zugestellt?
            </v-col>
          </v-row>
          <v-card
            class="mt-2 mb-4"
            v-for="item in datasets"
            :key="item.Bestellung_ID"
          >
            <v-col cols="11">
              <v-row dense align="center">
                <v-col cols="6" class="font-weight-bold">
                  Artikelnummer:
                </v-col>
                <v-col cols="6">
                  {{ item.Artikelnummer }}
                </v-col>
              </v-row>              
            </v-col>

            <v-btn
              small
              fab
              absolute
              bottom
              right
              @click="removeFromDelivered(item.Bestellung_ID)"
              ><v-icon color="error">mdi-minus</v-icon></v-btn
            >
          </v-card>
        </v-col>
      </v-row>
      <v-row dense align="center">
        <v-col cols="12">
          <span class="font-weight-bold">Bearbeiter(*)</span>
              <v-select
                hide-details
                dense
                outlined
                required
                placeholder="Bearbeiter(*)"
                v-model="selectedBearbeiter"
                :items="Bearbeiter"
                item-text="Vorname"
                item-value="Bearbeiter_ID"
              >
              </v-select>
        </v-col>
      </v-row>
        
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="pa-0">
          <v-row dense align="center">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn
                outlined
                color="red lighten-1"
                @click="abortDeliveredDatasets()"
              >
                <span>Abbrechen</span>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="green lighten-1"
                @click="confirmDeliveredDatasets()"
              >
                <v-icon left class="grey--text text--lighten-4">
                  mdi-check
                </v-icon>
                <span class="grey--text text--lighten-4">
                  Zustellung erfassen
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { initialize } from 'passport';
export default {
  data: () => ({
    DeliverDatasets: false,
    datasets: [],
    Bearbeiter: [],
    selectedBearbeiter:{},
  }),

  methods: {
    open(items) {
      const selected = items;
      const datasets = [];

      selected.forEach(async function(item) {
        datasets.push(item);
      });

      this.datasets = datasets;
      this.DeliverDatasets = true;

      this.initialize();
    },

    async initialize() {
      const response = await fetch(`/api/database/articles/dataset/insert`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }
    },

    confirmDeliveredDatasets() {
      this.DeliverDatasets = false;
      this.$emit("confirmDeliveredDatasets", this.datasets, this.selectedBearbeiter);
    },

    clearBearbeiter() {
      this.selectedBearbeiter = {};
    },

    abortDeliveredDatasets() {
      this.DeliverDatasets = false;
    },

    removeFromDelivered(id) {
      var index = this.datasets
        .map((x) => {
          return x.Bestellung_ID;
        })
        .indexOf(id);

      this.datasets.splice(index, 1);

      if (this.datasets.length === 0) {
        this.abortDeliveredDatasets();
      }
    },
  },
};
</script>
